/**
 *
 * @description LoginForm.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 29 October 2021
 *
 */

import { Form, Input, Button, Row, Col, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';
import { AuthContext, AuthContextT } from '~/v2/context/AuthProvider';
import { useContext } from 'react';

export type LoginFormValues = {
  password: string;
  email: string;
};

export default function LoginForm() {
  const authCtx = useContext<Partial<AuthContextT>>(AuthContext);

  const onFinish = async (values: LoginFormValues) => {
    const { email, password } = values;

    await authCtx.onSignIn?.(email, password, '/packages');
  };

  const onFinishFailed = () => {
    message.error({
      content: 'Something went wrong. We are alert to it. Please try again later.',
    });
  };

  return (
    <div className='auth'>
      <Row>
        <Col xs={24} md={{ span: 12, offset: 6 }}>
          <h2>
            <FormattedMessage id='app.common.login' defaultMessage='Login' />
          </h2>
          <div className='auth-form'>
            <Form layout='vertical' name='login' onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Form.Item
                label='Email address'
                name='email'
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                    type: 'email',
                  },
                ]}
              >
                <Input autoComplete='email' />
              </Form.Item>

              <Form.Item
                label='Password'
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password autoComplete='current-password' />
              </Form.Item>
              <Button type='link'>
                <Link href='/forgot-password'>
                  <a>
                    <FormattedMessage id='app.common.forgot_password' defaultMessage='Forgot Password' />
                  </a>
                </Link>
              </Button>
              <Form.Item className='form-submit'>
                <Button type='primary' htmlType='submit' className='text-main-400'>
                  <FormattedMessage id='app.common.login' defaultMessage='Login' />
                </Button>
                <Button type='link'>
                  <Link href='/register'>
                    <a>
                      <FormattedMessage id='app.common.create_account' defaultMessage='OR CREATE AN ACCOUNT' />
                    </a>
                  </Link>
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}
